@import "~Stylesheets/base";

.Header {
  color: @gray900;
  font-weight: normal;
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: 1.45;
}

.Header--inline {
  display: inline-block;
}

.Header--h1 {
  font-size: 24px;
  font-weight: 600;
  // line-height: 36px;
}

.Header--h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.Header--h3 {
  font-size: 18px;
  line-height: 24px;
}

.Header--h4 {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}

.Header--h5 {
  font-size: 14px;
  line-height: 20px;
  color: @gray600;
}

.Header--h6 {
  font-size: 12px;
  line-height: 16px;
}

.Header--h1-intuit {
  font-size: 44px;
  line-height: 52px;
  font-weight: 700;
  color: @qbDarkGray;
}

.Header--h2-intuit {
  font-size: 36px;
  line-height: 44px;
  font-weight: 700;
  color: @qbDarkGray;
}

.Header--h3-intuit {
  font-size: 36px;
  line-height: 44px;
  font-weight: 400;
  color: @qbDarkGray;
}

.Header--h4-intuit {
  font-size: 28px;
  line-height: 36px;
  font-weight: 600;
  color: @qbDarkGray;
}

.Header--h5-intuit {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: @qbDarkGray;
}

.Header--h6-intuit {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: @qbDarkGray;
}

.Header--h7-intuit {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: @qbDarkGray;
}

.Header--h8-intuit {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: @qbDarkGray;
}

.Header--h9-intuit {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: @qbDarkGray;
}
