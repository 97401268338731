@import "~Stylesheets/base";
.AddMedicalPlansCard {
  border-radius: 0px;
}

.AddMedicalPlansToggle {
  font-size: 18px;
  color: @blue400;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px !important;

  &:hover {
    background-color: darken(white, 10%);
    cursor: pointer;
  }

  &--intuit {
    color: @qbDarkGray;
    font-size: 16px;

    .Button--link-intuit {
      color: @qbDarkGray;
    }

    &:hover {
      background-color: @qbGray8;
    }
  }
}

.AddMedicalPlansContent {
  border-top: 1px solid #e0e0e0;
  .CobaltTable__Cell:last-child{
    width: 32px;
  }
}
