// Not fully converted to BEM style, do not add or copy css in the old style, need
// the old css pushed down into BEM labeled namespaces
@import (reference) "~Stylesheets/variables";

.EmployeeMultiplePlans {
  font-size: 16px;
  font-weight: normal;

  td {
    width: 180px;
    padding: 5px 10px;
  }

  .MultiplePlanHeader__providerLogoImage {
    width: 50%;
    height: auto;
  }

  th {
    width: 200px;
    text-align: left;
    text-size: 24px;
  }

  tr.row-separator th {
    font-weight: 400;
  }
}

.MultiplePlanPricing {
  &__specificCostRow {
    th {
      font-weight: normal;
    }
  }
}
