@import "~Stylesheets/base";

.SIDropdown {
  position: relative;
  display: inline-block;

  &__contentWrapper {
    position: absolute;
    top: 100%;
    display: none;

    &--open {
      display: block;
      z-index: @zindex-dropdown;
    }

    &--position-left {
      left: 0;
    }
    &--position-right {
      right: 0;
    }
  }

  &__ContentBox {
    background-color: @white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    background-clip: padding-box;

    &--intuit {
      border: 1px solid @qbGray4;
      border-radius: 2px;
      box-shadow: 0 4px 0 0 @qbGray7;
    }
  }
}
