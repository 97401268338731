@import (reference) "~Stylesheets/base.less";
@import "~bootstrap-less/bootstrap/responsive-utilities";

@grid-gutter-width-intuit: 32px;

@container-lg: 1148px;

.Gridv3 {
  @media (min-width: @screen-sm-min) {
    width: @container-sm;
  }
  @media (min-width: @screen-md-min) {
    width: @container-md;
  }
  @media (min-width: @screen-lg-min) {
    width: @container-lg;
  }

  padding-left: @grid-gutter-width-intuit / 2;
  padding-right: @grid-gutter-width-intuit / 2;

  .row {
    margin-left: -@grid-gutter-width-intuit / 2;
    margin-right: -@grid-gutter-width-intuit / 2;
  }

  // Common styles for all sizes of grid columns, widths 1-12
  .col(@index) when (@index =< @grid-columns) {
    // initial
    & .col-xs-@{index},
    & .col-sm-@{index},
    & .col-md-@{index},
    & .col-lg-@{index} {
      padding-left: @grid-gutter-width-intuit / 2;
      padding-right: @grid-gutter-width-intuit / 2;
    }
    .col((@index + 1));
  }
}

.Hidden-xs {
  .hidden-xs;
}
.Hidden-sm {
  .hidden-sm;
}
.Hidden-md {
  .hidden-md;
}
.Hidden-lg {
  .hidden-lg;
}
