@import (reference) "~Stylesheets/variables";
@import "~Stylesheets/base";

.AgentHeader {
  position: relative;
  text-align: center;
  z-index: 1;
  padding: 24px 16px;
  text-align: left;
  display: flex;

  @media (max-width: @screen-xs-max) {
    display: none;
  }

  &__agentText {
    border-radius: 1.15rem;
    line-height: 1.25;
    max-width: 80%;
    padding: 0.5rem 0.875rem;
    position: relative;
    word-wrap: break-word;
    align-items: flex-start;
    background-color: @gray200;
    color: #000;
    margin-left: 12px;
    height: fit-content;
  }

  &__agentText::before,
  &__agentText::after {
    bottom: -0.1rem;
    content: "";
    height: 1rem;
    position: absolute;
  }

  &__agentText:before {
    border-bottom-right-radius: 0.8rem 0.7rem;
    border-left: 1rem solid @gray200;
    left: -0.35rem;
    transform: translate(0, -0.1rem);
  }

  &__agentText::after {
    background-color: #fff;
    border-bottom-right-radius: 0.5rem;
    left: 20px;
    transform: translate(-30px, -2px);
    width: 10px;
  }

  &__agentText.hasGrayBackground:after {
    background-color: @gray100;
  }
}

.AgentHeader:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 1px;
  background: @gray200;
  z-index: -1;
  margin: 1px 0 0;
}
