@import (reference) "~Stylesheets/base.less";
@import "~Cobalt/typography";

.SavedPlanBox {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 32px;
  min-width: 140px;
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  float: left;
  background-color: @gray200;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 2px;

  @media (min-width: @screen-sm-min) {
    height: 55px;
    max-width: 192px;
    min-width: unset;
  }

  &__PlanInformation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (min-width: @screen-sm-min) {
      gap: @sp-3;
    }
  }

  &__RemoveIcon {
    display: block;
    margin-right: @sp-3;
  }

  &--intuit {
    height: auto;
    box-shadow: none;
    border-radius: 3px;
    border: solid 1px @qbGray4;
    width: 100%;
  }

  &__remove {
    color: @red800;
    cursor: pointer;

    &:hover {
      color: @red900;
    }

    &--intuit {
      transform: scale(0.75);
      float: right;
    }
  }

  &__centeredText {
    text-align: center;
  }

  &__text {
    .overflowEllipsis();
    pointer-events: none;
    display: inline-block;
    width: 100%;
    margin: 0 @sp-2;
    flex-grow: 1; // Allow text to take available space
    color: @gray700;

    @media (min-width: @screen-sm-min) {
      // Set max height to allow up to 2 lines
      max-height: 2.8em;
      white-space: normal;
    }
  }
}
