@import "~Stylesheets/base";

.LifePlanCard {
  &__requestButton {
    &--active,
    &--active:hover,
    &--active:focus {
      background-color: #eaf2e7;
      color: @qbGreen;
      padding-left: 12px;
      border: 1px solid @qbGreen;
    }
  }

  &__benefit {
    display: inline-flex;

    span {
      align-self: baseline;
      line-height: 20px;
    }
  }
}
