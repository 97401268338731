@import (reference) "~Stylesheets/base";

.SIPopover {
  &--intuit {
    border-radius: 2px;
    box-shadow: none;
    border: 1px solid @qbGray4;
    background-color: @white;

    // Jankiness to override react bootstrap
    // It doesn't allow for multiple class names
    // to be passed to bsClass
    > .SIPopover--intuit.popover-title {
      background-color: @white;
      border-top: none;
      border-left: none;
      border-right: none;
    }
    > .SIPopover--intuit.popover-content {
      border: none;
    }
  }

  &--inverted {
    background-color: @blue800;
    color: @blue25;
    .arrowColor(@blue800);

    a {
      color: @blue25;
      text-decoration: underline;
      :hover {
        color: @blue400;
        text-decoration-color: @blue400;
      }
    }
  }

  .popover-title {
    // To override some settings from application.css
    color: inherit;
    font-weight: 500 !important;
    line-height: 1.1;
  }

  > .arrow {
    .popover > .arrow;
    border-color: @qbGray4;
  }

  > .arrow:after {
    border-width: @popover-arrow-width;
    content: "";
  }
}
