@import "~Stylesheets/base";

.Card {
  background-color: @white;
  border-radius: 2px;

  .tab-content {
    overflow: visible;
  }

  // Themes
  &--hoverable {
    .Elevation--1;
    transition: box-shadow @basicTransition;

    &:hover {
      .Elevation--8;
    }
  }

  &--flat {
    border: 1px solid @gray300;
  }

  &--elevated {
    .Elevation--1;
  }

  &--disabled {
    background-color: @gray200;
  }

  &--intuit {
    &-flat,
    &-disabled,
    &-hoverable,
    &-elevated {
      padding: 20px;
      border-radius: 6px;
      border: 1px solid @qbGray6;
    }

    &-flat,
    &-disabled {
      box-shadow: none;
    }

    &-disabled {
      background-color: @qbGray5;
    }

    &-hoverable,
    &-elevated {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    }

    &-hoverable {
      transition: box-shadow 0.2s cubic-bezier(0.3, 0, 0.15, 1);
      &:hover {
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
      }
    }
  }

  &.plan-detail-image-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      max-width: 200px;
      margin-right: 20px;
    }
  }
}

.Card__Section + .Card__Section {
  border-top: 1px solid @gray300;
}

.Card__Header {
  border-bottom: 1px solid @gray300;
  color: @gray900;
  font-size: 18px;
  margin-left: -20px;
  margin-right: -20px;
  padding-bottom: 10px;
}

.Card__HeaderOffset {
  padding-left: 20px;
  padding-right: 20px;
}

.Card__Separator {
  text-align: center;
  color: @gray400;
  font-size: 8px;

  i + i {
    margin-left: 5px;
  }
}

.Card__CollapsibleHeader {
  cursor: pointer;
}

.Card__Footer {
  background-color: @gray100;
}
