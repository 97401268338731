@import "~Stylesheets/base";

.OwnersAsEmployees {
  &__OwnerHeader {
    padding-left: 15px;
  }

  &__OwnerRow {
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
  }

  &__OwnerRowDelete {
    color: @error900;
  }
}
