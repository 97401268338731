@import (reference) "~Stylesheets/base";

.fade.modal-backdrop {
  position: fixed;
}

.modal-header {
  &--LeftCloseButton {
    .close {
      float: left;
      font-size: 5rem;
      margin-left: -55px;
      color: @white;
      opacity: 1;
    }
  }
}

.modal-backdrop.fade.in {
  opacity: 0.5;
}

@media (min-width: @screen-sm-min) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    width: 100%;
    max-width: 760px;
    margin: 80px auto;
  }

  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }

  // Modal sizes
  .modal-sm {
    width: @modal-sm;
  }
}

@media (min-width: @screen-md-min) {
  .modal-lg {
    width: @modal-lg;
  }
}

.modal-xl {
  .modal-dialog {
    min-width: @modal-xl;
  }
}

// Custom Styles

.modal.fullscreen-modal {
  // TODO abstract header height into a variable, use variable.
  top: 40px;
  overflow: hidden;

  .modal-dialog {
    height: 100%;
    overflow: hidden;

    .modal-content {
      height: 100%;
      // .Row is 2px wider than the modal-body because it's expecting 16px padding not 15.
      overflow-x: hidden;
      overflow-y: scroll;
      border-radius: 0;
      box-shadow: none;
      border: none;
    }
  }
}

.Modal__Backdrop--intuit.fade {
  background: rgba(0, 0, 0, 0.6);

  &.in {
    opacity: 1;
  }
}

.Modal__Dialog--intuit {
  .modal-content {
    box-shadow: none;
    border: none;
    border-radius: 6px;
    overflow: hidden;
    padding: 15px;
  }
  .modal-header {
    padding: 0px 0px 10px 0px;
  }
  .modal-body {
    padding: 0px 0px 10px 0px;
  }
  &.modal-xl {
    .modal-dialog {
      min-width: @modal-xl;
    }
  }
  .modal-dialog {
    @media (min-width: @screen-md-min) {
      min-width: 800px;
    }
  }
  button.close {
    float: right;
    font-size: 24px;
  }
}

.Modal__Header--intuit {
  border-bottom: none;
}

.Modal__Footer--intuit {
  @media (min-width: @screen-sm-min) {
    border-top: 1px solid @qbGray5;
    padding: 15px 0px 0px 0px;
  }
  @media (max-width: @screen-sm-min) {
    border-top: none;
    padding: 0px 0px 0px 0px;
  }
}

.Modal__Footer--intuit-center {
  display: flex;
  justify-content: center;
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;

  .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-header {
    border-radius: 0;
    border: 0;
  }

  .modal-body {
    overflow-y: auto;
  }

  .modal-footer {
    border-radius: 0;
    border: 0;
  }
}
