.LargeGroupPageWrapper {
  &__wizardPlacement {
    margin: 180px 0 180px auto;
  }

  &__content {
    &::before {
      content: "";
      position: absolute;
      top: 0px;
      height: 130%;
      width: 100vw;
      right: 0;
      background-color: white;
      z-index: -1;
    }
  }
}
