@import "~Stylesheets/base";

.ExpandingPlanCard {
  position: relative;
  border-radius: 0px;
  transition: margin-bottom 0.25s;
  transition: margin-top 0.25s;
  border: 1px solid #e3e5e8;

  &__Container {
    display: flex;
    min-height: 80px;
    align-items: center;
    cursor: pointer;
  }

  &__CheckBoxContainer {
    text-align: center;
  }

  &__PlanCostContainer {
    text-align: right;
  }

  &__ToggleIconContainer {
    text-align: center;
  }

  &__IconBoxWrapper {
    &--bestValue {
      color: @greenA700;
    }

    &--moreDoctors {
      color: @blue400;
    }

    &--lowCopays {
      color: @gold;
    }

    &--bestMatch {
      color: @greenA700;
    }
  }
}

.ExpandingPlanCardMobile {
  &__d-flex {
    display: flex;

    &.main-wrapper {
      min-height: 80px;
    }

    .plan-details-wrapper {
      width: 100%;
    }

    .logo {
      max-height: 70px;
    }

    .flex-space-between {
      justify-content: space-between;
    }
  }
}

.Badges {
  div:not(:first-child) {
    margin-left: 4px;
  }
}
