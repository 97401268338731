@import (reference) "~Stylesheets/base.less";

.SIFormField {
  &__Validation {
    line-height: 20px;
    opacity: 0;
    transition: opacity @basicTransition;

    &--noBounce {
      height: 20px;
    }

    &--error {
      color: @red800;
      opacity: 1;
    }
    &--warning {
      color: @orange500;
      opacity: 1;
    }
  }
}
