@import "~Stylesheets/base";

.EditEmployeeDetailsModal {
  &__EmployeeDetailsInput {
    input& {
      border: 1px solid transparent !important;
    }
  }
  &__EmployeeDetailsColumnThin {
    width: 8%;
    vertical-align: middle !important;
  }
  &__EmployeeDetailsAgeColumn {
    width: 5%;
    vertical-align: middle !important;
  }
  &__EmployeeDetailsColumn {
    vertical-align: middle !important;
  }
  &__EmployeeDetailsCostColumn {
    vertical-align: middle !important;
    text-align: right;
  }
  &__DecliningAllPlans {
    color: @gray500;
  }
  &__EmployeeDetailsAddEmployeeColumn {
    vertical-align: middle !important;
    text-align: left;
  }
  &__DependentCostsRow {
    height: 49.5px;
  }
}
