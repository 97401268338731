@import "~Stylesheets/base.less";
@import "~Cobalt/typography";

.SavedPlansTray {
  position: fixed;
  z-index: @zindex-tray;
  right: 0;
  bottom: 0;
  left: 0;
  background: @blue800;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.16);

  &--intuit {
    // Added translucence to @blue800
    background: fade(@blue800, 90%);
    box-shadow: none;
  }

  &__Container--desktop {
    display: flex;
    flex-direction: column;
    gap: @sp-3;
    @media (min-width: @screen-sm-min) {
      justify-content: space-between;
      flex-direction: row;
      gap: @sp-xl;
    }
  }

  &__PlanBoxes {
    display: flex;
    flex-grow: 1;
    gap: @sp-3;
    overflow-x: auto;
  }

  &__LinkContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .body2();
  }

  &__Controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: @sp-2;
  }

  &__CompareButton {
    width: 100%;
    @media (min-width: @screen-sm-min) {
      width: 150px;
    }
  }

  &__PrintButton {
    display: inline-block;
  }

  &__ClearAllButton {
    color: @blue100;
    display: inline-block;
    vertical-align: bottom;
  }
}
