@tailwind components;
@tailwind utilities;

@import "~Stylesheets/base";
@import "~Cobalt/typography";

.flexRow {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: @sp-5;
  @media (min-width: @screen-sm-max) {
    flex-direction: row;
  }
}

.careSection {
  padding: @sp-4 @sp-2;
  @media (min-width: @screen-sm-max) {
    padding: @sp-xl * 3;
  }
}
