@import (reference) "~Stylesheets/variables.less";

.NavigationControls {
  border-top: 1px solid @gray300;
}

.NavigationControls.StepForm.TopNav {
  border-bottom: 1px solid @gray300;
}

.NavigationControls.TopNav {
  border-top: none;
}

.NavigationControls__smallNavBtns {
  .Button--intuit-trowser-medium {
    min-width: 90px;
  }
}

.NavigationControls__left {
  text-align: left;
}
.NavigationControls__middle {
  text-align: center;
}
.NavigationControls__right {
  text-align: right;
  float: right;
}
