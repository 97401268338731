@import "~Stylesheets/variables";

@specialGray: #fafafa;
@borderColor: #c8c8c8;

.ShoppingTabs {
  &__tabs {
    border-bottom: 1px solid @gray300;

    // Overriding bootstrap's tab css for tab components
    & .nav-tabs > li > a,
    & .nav-tabs > li.active > a {
      &,
      &:hover,
      &:focus {
        border: none;
        background: transparent;
        color: @gray900;
        position: relative;
        padding: 8px 16px 13px; // I'm REALLY Sorry, David =/
      }

      &:after {
        display: block;
        position: absolute;
        content: " ";
        width: 100%;
        bottom: -1px;
        left: 0;
        height: 5px;
        background: @blue400;
        opacity: 0;
      }
    }
    & .nav-tabs > li > a {
      &:hover,
      &:focus {
        &:after {
          opacity: 0.3;
        }
      }
    }

    & .nav-tabs > li.active > a {
      font-weight: 600;

      &:after {
        opacity: 1;
      }
    }
  }

  &__card {
    background-color: @specialGray;
    box-shadow: none;
    border: 1px solid @borderColor;
  }

  &__favoriteTabIcon {
    color: red;
  }
}
