@import (reference) "~Stylesheets/base";

.DropdownButton {
  white-space: nowrap;
  font-size: 0;
  margin-left: 10px;
  text-align: left;
  position: relative;

  &__toggle-button {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    font-size: 14px;
    box-sizing: border-box;
    background-color: @white;
    text-decoration: none;
    color: #212121;
    margin-left: 0;
    border-radius: 0px;
    cursor: pointer;
    &:hover,
    &:focus {
      background-color: @gray200;
      text-decoration: none;
    }

    &--small {
      padding: 4px 14px;
    }

    &--xs {
      padding: 4px 14px;
      min-width: 90px;
      border-radius: 25px;
      height: 30px;

      .DropdownButton__label--intuit {
        line-height: 22px;
        height: 22px;
      }

      .DropdownButton__carat--intuit {
        top: 4px;
      }
    }

    &--intuit {
      border: 1px solid @qbGray4;
      border-radius: 2px;

      &:hover {
        box-shadow: 0 0 0 2px @qbGray5;
        background-color: @qbGray7;
      }

      &:hover,
      &:focus {
        background-color: @white;
        color: @black;
      }
    }

    &--gray {
      border-color: @gray100;
      background-color: @gray100;
      box-shadow: none;

      &:hover,
      &:focus {
        background-color: @gray200;
        box-shadow: none;
      }
    }
  }

  &__filter {
    border-radius: 25px;
    border-style: solid;
    border-width: 1px;

    &--not-selected {
      border-color: #dadce0;
    }

    &--selected {
      padding: 4px 14px;
      background-color: lighten(@blue500, 45%);
      border-color: @blue500;
      color: @blue500;
    }

    &--intuit {
      .DropdownButton__carat--intuit {
        right: 12px;
      }
    }
  }

  @media (max-width: @screen-xs-max) {
    &__toggle-button {
      font-size: 14px;
    }

    &__filter-selected {
      font-size: 11px;
      padding: 6px;
      max-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media (min-width: @screen-xs-min) {
    &__filter {
      padding: 5px 14px;

      &--selected {
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &--intuit {
        padding: 4px 4px 2px;
      }
    }
  }

  &__carat {
    display: inline-block;
    float: right;
    &--intuit {
      display: inline-block;
      float: none;
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

  &__sort-dropdown {
    border-radius: 25px;
    border: transparent;
  }

  &__dropdown-options {
    position: absolute;
    top: 100%;
    left: 0px;
    z-index: @zindex-dropdown;
    margin: 2px 0 0;
    list-style: none;
    background-color: @white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
    font-size: 14px;
    padding: 4px;
    // At least match width of button, otherwise it feels broken
    min-width: 100%;

    &--position-right {
      left: auto;
      right: 0;
    }

    &--small {
      min-width: 145px;
    }

    &--filter {
      width: fit-content;
      border-radius: 0px;
    }

    &--closed {
      display: none;
    }
    &--open {
      display: block;
    }
    &--intuit {
      border: 1px solid @qbGray4;
      border-radius: 2px;
      box-shadow: 0 4px 0 0 @qbGray7;
      margin: 3px 1px;
    }

    @media (max-width: @screen-xs-max) {
      right: 0px;
      left: inherit;
      &--intuit {
        right: -2px;
      }
      &__filter {
        font-size: 11px;
      }

      &--open {
        padding: 6px;
      }
    }

    @media (min-width: @screen-xs-min) {
      &__filter {
        font-size: 16px;
        padding: 5px;
        margin: 5px;
        min-width: 180px;
      }
    }
    &--filter {
      width: fit-content;
      border-radius: 0px;

      &--intuit {
        width: auto;
      }
    }

    &--closed {
      display: none;
    }
    &--open {
      display: block;
    }
    &--intuit {
      border: 1px solid @qbGray4;
      border-radius: 2px;
      box-shadow: 0 4px 0 0 @qbGray7;
      margin: 3px 1px;
    }
  }
  &__label {
    @media (max-width: @screen-xs-max) {
      padding-right: 2px;
    }

    @media (min-width: @screen-xs-min) {
      padding-right: 7px;
    }

    &--intuit {
      padding-right: 0px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 28px;
      padding-right: 0;
      line-height: 32px;
      height: 32px;
      padding-left: 10px;
    }
  }
}
