@import (reference) "~Stylesheets/base.less";

.PillNavTabs__navItem {
  max-width: 50%;
  white-space: nowrap;
  text-overflow: ellipsis;

  &--intuit {
    a {
      color: #333;
      &:hover {
        color: #333;
      }
    }
    &.active {
      a {
        background-color: @qbGray2 !important;
      }
    }
  }
}
