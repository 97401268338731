@import "~Stylesheets/base";

.PlansFilterPanelDumb {
  margin: 0px 5px 5px 5px;

  &__menu-more {
    border-radius: 10px;
    margin-top: 9px;
    padding: 0 10px 10px;
    left: auto;
    right: 0;

    &--intuit {
      width: auto;
    }
  }

  &__menu-more-ul {
    padding-left: 0;
    list-style: none;
  }

  &__budget-modal-button {
    &:hover {
      text-decoration: none;
    }
  }

  &__budget-modal-link {
    color: black;
    border-bottom: dashed 1px;
  }
}
