@import "~Stylesheets/base";
@import "~Cobalt/typography";
@import "~QuotePage/app/compactQuotePage/ShoppingTabs";

.DynamicPlanDetails {
  .ShoppingTabs;
  &__tabs {
    border-bottom: 0;
    .nav.nav-tabs {
      padding: 0 @sp-4;
    }
    // Future: should be flexbox and justify-between
  }

  &__Section {
    display: flex;
    flex-direction: column;
    gap: @sp-2;
    @media (min-width: @screen-sm-max) {
      gap: @sp-4;
    }
  }
}
