@import "~Stylesheets/base";
@import "./typography";

.CobaltTableV2 {
  width: 100%;
  max-width: 100%;

  &__HeaderRow {
    height: 40px;
    background-color: @gray100;
    border-top: 1px solid @gray200;
    border-bottom: 1px solid @gray200;
  }

  &__Row {
    height: 48px;
    transition: background-color 0.35s ease-in;

    &--bg-gray {
      background-color: @gray100;
    }

    &--with-border {
      border-bottom: 1px solid @gray200;
    }

    &--highlight-first-cell {
      .CobaltTableV2__Cell:first-child {
        font-weight: @cobaltFontWeightBold;
        color: @gray900;
      }
    }

    &--indented {
      .CobaltTableV2__Cell:first-child {
        padding-left: 16px;
      }
    }

    &--detail {
      height: 36px;
      .CobaltTableV2__Cell {
        .subBody();
      }
    }
  }

  &__SpacerRow {
    height: 2px;
  }

  &__FooterRow {
    height: 40px;
  }

  &__HeaderCell,
  &__Cell,
  &__FooterCell {
    padding: 0px 8px 0px 8px;
    vertical-align: middle;

    &:last-child {
      text-align: right;
    }
  }

  &__HeaderCell {
    .caption();
    font-weight: @cobaltFontWeightBold;
    text-transform: uppercase;
    background-color: @blue25;
    white-space: nowrap;

    &--fit-content {
      width: 0px;
    }
  }

  &__Cell {
    .body2();
    color: @gray700;

    &--toggle {
      padding: 0;
      width: 40px;
    }

    &--fit-content {
      width: 0px;
    }
  }

  &__EllipsisTD {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__ToggleCell {
    padding: 0;
    width: 40px;
    color: @gray700;
  }

  &__FooterCell {
    .body2();
    font-weight: @cobaltFontWeightBold;
    color: @gray900;
    white-space: nowrap;
  }

  &__CollapseBody {
    &.collapsing {
      display: block;
      opacity: 0;
      width: 0;
    }
    &.in {
      opacity: 1;
      transition: opacity 0.1s linear;
    }
  }

  &__CollapseToggleButton {
    padding: 0;
    border: 0;
    height: 40px;
    width: 40px;
    background-color: transparent;
    color: inherit;

    &:hover {
      background-color: @gray200;
    }
  }
}
