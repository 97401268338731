.DropdownMultiSelectFilter {
  border-radius: 10px;

  &--intuit {
    width: auto;
  }

  &__only {
    font-size: 11px;
    visibility: hidden;

    &--show {
      visibility: visible;
    }
  }
}
