@import "~Stylesheets/variables";

.MECQuotePage {
  &__MECCallout {
    max-width: 100%;
  }

  &__InfoContainer {
    transition: background-color 0.25s ease, color 0.25s ease;
    background-color: @blue800;
    &--Open {
      background-color: @white;
    }
    &--Header {
      padding: 0px !important;
      cursor: pointer;
    }
    &--Text {
      color: @white;
    }
    &--OpenText {
      color: @gray900;
    }
  }
  &__Table {
    border: 0px !important;
    th,
    td {
      text-align: left !important;
    }
  }

  &__MECPlanCard {
    &--Header {
      span {
        display: inline-grid;
        vertical-align: baseline;
        &:last-child {
          float: right;
          text-align: right;
          margin-top: -12px;
        }
      }
    }
    &--Detail {
      div {
        display: inline-block;
        &:last-child {
          float: right;
          text-align: right;
        }
      }
    }
  }

  &__filterPanel {
    display: flex;
    justify-content: flex-end;
  }
}
