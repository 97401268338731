.flex {
  display: flex;
  justify-content: flex-end;
}

.flex-spaced {
  display: flex;
  justify-content: space-around;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

// The following is intended to be used as a stopgap solution for containers that
// need to leverage flexbox, and still need to align with existing grid organization.
// Once we upgrade to a version of react-bootstrap that uses Bootstrap 4's flexbox
// grid, we should set this on fire and refactor code that uses it.

.flex-col-1, .flex-col-2, .flex-col-3, .flex-col-4, .flex-col-5, .flex-col-6,
.flex-col-7, .flex-col-8, .flex-col-9, .flex-col-10, .flex-col-11, .flex-col-12 {
  padding-left: 16px;
  padding-right: 16px;
}

.flex-col-1 {
  width: 8.33333333%;
}

.flex-col-2 {
  width: 16.66666667%;
}

.flex-col-3 {
  width: 25%;
}

.flex-col-4 {
  width: 33.33333333%;
}

.flex-col-5 {
  width: 41.66666667%;
}

.flex-col-6 {
  width: 50%;
}

.flex-col-7 {
  width: 58.33333333%;
}

.flex-col-8 {
  width: 66.66666667%;
}

.flex-col-9 {
  width: 75%;
}

.flex-col-10 {
  width: 83.33333333%;
}

.flex-col-11 {
  width: 91.66666667%;
}

.flex-col-12 {
  width: 100%;
}
