@import (reference) "~Stylesheets/base";

.CobaltButton {
  transition: opacity @basicTransition;
  border: none;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.24), 0px 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  box-sizing: border-box;
  text-decoration: none;
  text-shadow: none;

  &:focus,
  &:active {
    outline: 0;
  }

  &--fullWidth {
    display: block;
    width: 100%;
  }

  // Colors
  &--primary {
    background-color: var(--primaryColor, @primaryColor);
    color: @white;

    &:hover,
    &:focus {
      background-color: var(--primaryColorDarken10, @blue600);
      color: @white;
    }

    &:active {
      background-color: var(--primaryColorDarken20, @blue700);
      color: @white;
    }
  }

  &--secondary {
    background-color: var(--primaryColor, @blue50);
    color: @primaryColor;

    &:hover,
    &:focus {
      background-color: var(--primaryColorDarken10, @blue100);
      color: @primaryColor;
    }

    &:active {
      background-color: var(--primaryColorDarken20, @blue200);
      color: @primaryColor;
    }
  }

  &--white {
    background-color: @white;
    color: @gray900;

    &:hover,
    &:focus {
      background-color: @gray100;
      color: @gray900;
    }

    &:active {
      background-color: @gray200;
      color: @gray900;
    }
  }

  &--gray {
    background-color: @gray200;
    color: @gray900;
    text-decoration: none;

    &:hover,
    &:focus {
      background-color: @gray300;
      color: @gray900;
    }

    &:active {
      background-color: @gray400;
      color: @gray900;
    }
  }

  // Sizes
  &--small {
    padding: 4px 16px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }

  &--medium {
    padding: 6px 16px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }

  &--large {
    padding: 16px 20px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }

  // Link variation
  &--tertiary, &--link {
    background-color: transparent;
    color: @blue500;
    box-shadow: none;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      box-shadow: none;
      color: @blue600;
      text-decoration: underline;
    }

    &:active {
      color: @blue700;
    }
  }

  &--info {
    background-color: transparent;
    color: @gray700;
    box-shadow: none;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      box-shadow: none;
      color: @gray700;
      text-decoration: underline;
    }

    &:active {
      color: @gray700;
    }
  }

  &--destructive {
    background-color: transparent;
    color: @error900;
    box-shadow: none;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      box-shadow: none;
      color: @error900;
      text-decoration: underline;
    }

    &:active {
      color: @error900;
    }
  }
}
