@import "~Stylesheets/base";

.QuoteInformationPanel {
  &__PanelSectionDivider {
    margin-top: 8px;
    margin-bottom: 12px;
    border-top: 1px solid @gray300;
  }

  &--SubBody {
    color: @gray600;
    padding-top: 2px;
  }

  &__InsuranceTypeTitle {
    font-weight: 600;
  }

  &__PlanDetailsContainer {
    display: flex;
    justify-content: space-between;
  }

  &__NewQuarterNoticeAnnual {
    display: flex;
    background-color: @error50;
    border-radius: 12px;
    border: 2px solid @error900;

  }

  &__NewQuarterNoticeQuarterly {
    display: flex;
    background-color: @warning50;
    border-radius: 12px;
    border: 2px solid @warning900;
  }
}
