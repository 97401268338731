@import (reference) "~Stylesheets/base.less";

.SIFormLabel {
  &--intuit {
    font-weight: 600;
    margin-bottom: 0;
  }

  &--intuit-error {
    color: @qbRed2;
  }

  &__Hint {
    margin-left: 0.5em;
  }
}
