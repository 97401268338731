@import (reference) "~Stylesheets/base.less";

.ResponsiveTopBar {
  color: #fff;
  min-height: var(--header-height);
  background-color: var(--primaryColor, @primaryColor);
  margin-bottom: 0px;
  border: none;
  border-radius: 0px;
  padding: 16px 0;
  z-index: 1050;

  & > .container {
    max-width: 1384px;
    width: 100%;

    & > .Gridv3 {
      width: 100%;
    }
  }

  &--intuit {
    background-color: @qbDarkGray;
  }

  &--bambee {
    background-color: @bambeePurple;
  }

  .dropdown-toggle .caret {
    display: none;
  }

  .navbar-right {
    margin-right: 0px !important;
  }

  .navbar-nav {
    & > li {
      margin-right: 24px;

      &:last-of-type {
        margin-right: 0;
      }

      & > a {
        color: #fff;
        cursor: pointer;
        padding: 0;
        line-height: 48px;
        font-size: 16px;
        letter-spacing: 1px;
        text-decoration: none;

        &:hover {
          color: #fff;
          text-decoration: underline;
        }

        &:focus {
          color: #fff;
        }
      }
    }
  }

  &--intuit {
    .navbar-nav > li > a {
      color: #babec5;

      &:focus {
        color: #babec5;
      }

      &:hover {
        text-decoration: none;
        background-color: #000;
        color: #fff;
      }

      // Note, this is a hack to deal with the fact that we can't tell
      // react-bootstrap's navbar to collaps at "lg" instead of "sm" with v0.33
      @media (max-width: @screen-md-max) and (min-width: @screen-sm-min) {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }

  &__Logo {
    height: 48px;
    padding: 0px;
    margin-right: 32px;
  }

  &--intuit &__Logo {
    padding: 0 15px;
    margin-left: -15px;
    margin-right: 0px;

    &:hover {
      background-color: #000;
    }
  }

  &__LogoContainer {
    height: 40px;
    padding: 0px;
    margin-left: 0px !important;
  }

  @media only screen and (max-width: 500px) {
    &__Logo {
      max-width: 100%;
      height: auto;
    }
    &--intuit &__Logo {
      padding: 0px;
      margin-left: 0px;
    }
    &__LogoContainer {
      padding: 0px;
      height: auto;
      width: 80%;
    }
  }

  &__Toggle {
    margin: 3px 10px 0 0;
    background-color: #eee;

    &:hover {
      background-color: #e0e0e0;
    }

    & .navbar-default {
      & .navbar-toggle {
        & .icon-bar {
          background-color: @blue500;

          &:hover {
            background-color: #e0e0e0;
          }
        }
      }
    }
  }
}

// The following classes are to support old .haml-based top bars
.TopBar {
  &__spacer,
  &__container {
    background-color: @topBarBackground;
    z-index: @zindex-navbar-fixed;
    min-height: var(--header-height);
    display: flex;
    align-items: center;

    & > .container {
      width: 100%;
      max-width: 1384px;
    }

    &--fixed {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }

    &--intuit {
      background-color: @qbDarkGray;
    }
  }

  &__fullGreeting {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    line-height: 10px;
  }

  &__greetingWrapper {
    height: 40px;
  }

  &__greetingLabel {
    margin-bottom: 10px;
  }

  &__menu {
    display: flex;
    overflow: hidden;

    // Align menuItems vertically
    align-items: flex-start;
    // Align menuItems horizontally
    justify-content: space-between;
  }

  &__menuGroup {
    display: flex;

    &--left {
      flex-shrink: 1000;

      justify-content: flex-start;
      flex-wrap: wrap;
      flex-grow: 2;
    }

    &--right {
      flex-shrink: 0;

      justify-content: flex-end;
      flex-wrap: wrap;
      flex-grow: 1;
    }
  }

  &__menuItem {
    height: 100%;
    color: white;
    display: inline-block;
  }

  &__menuLink {
    text-transform: uppercase;
    cursor: pointer;
    color: white;
    height: 100%;
    display: block;
    text-align: center;
    line-height: 40px;

    font-size: 16px;
    letter-spacing: 1px;
    text-decoration: none;

    &--padding {
      padding-left: 10px;
      padding-right: 10px;
    }

    &:hover {
      color: white;
      text-decoration: underline;
    }

    &:focus {
      color: white;
    }

    &--intuit {
      color: @qbGray4;
      background-color: @qbDarkGray;
      padding-left: 15px;
      padding-right: 15px;

      &:hover {
        color: white;
        background-color: black;
        text-decoration: none;
      }
    }
  }

  @media (max-width: @screen-xs-max) {
    &__menuLink {
      &--padding {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  &__backButtonLink {
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  &__backButtonIcon {
    font-size: 1.7em;
  }

  &__logoItem {
  }

  &__logoImage {
    vertical-align: top;
    height: 48px;
  }

  &__medicalDentalVisionContainer {
    margin-left: 30px;
  }

  &__quoteSummaryItemCount {
    color: @orangeA200;
  }

  &__greetingLink {
    max-width: 200px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: default;

    &:hover {
      text-decoration: none;
    }

    &--wide {
      max-width: 400px;
    }
  }
  &__logoutButton {
    min-width: 200px;
    overflow-x: hidden;
    text-align: left;
  }
}

//Screen width changes on navbar in GenericTopBar.js.  Currently using javascript for dynamic screen size changes based on icon

//
// Below styles are for the static HAML implementation of
// the top bar in app/views/shared/_top_bar.haml.
//

.TopBar {
  &__bodyContainer {
    margin-top: 40px !important;
  }

  &__hamburger {
    display: none;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  &__hamburgerOpenIcon {
    display: inline-block;
  }

  &__hamburgerCloseIcon {
    display: none;
  }

  &__dropdownContainer {
    display: none;
    margin-top: var(--header-height);
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: @zindex-max;
  }

  &__dropdownUl {
    // Kill the default ul styles
    padding: 0;
    list-style: none;

    display: none;
    margin: 0;
    border-bottom: 1px solid #ccc;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
  }

  &__dropdownLi {
    // forced to add !important because of the terrible
    // `.seo-big li` selector which beats BEM specificity.
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  &__dropdownLink {
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;

    height: 50px;
    line-height: 50px;

    color: black;
    background-color: #f4f5f7;

    &:hover {
      color: black;
    }
  }
}

@media only screen and (max-width: 815px) {
  .TopBar {
    &__greetingStatic {
      display: none;
    }
  }
}

@media only screen and (max-width: 550px) {
  .TopBar {
    &__hamburger {
      display: inline-block;
    }

    &__dropdownContainer {
      display: block;
    }

    &__resourcesStatic,
    &__help,
    &__greetingStatic,
    &__logout,
    &__login {
      display: none;
    }
  }
}
