@import "~Stylesheets/base";

.SICCodeSelectSearch {
  &__select__menu {
    position: relative !important;
  }

  &__select__option {
    transition: background 60ms;
    overflow: hidden;
    background: transparent !important;
    border-bottom: 1px solid @gray200;

    &:hover {
      transition-delay: 60ms;
      background: @blue200 !important;
    }
  }

  &__select__option--is-focused {
    background: none;
  }

  &__description,
  &__examples {
    // truncate text to 3lines
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    -webkit-box-pack: end;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__AncillaryIneligibilityBanner {
    background-color: @warning50;
    border-radius: 12px;
    border: 2px solid @warning900;
  }

  &__select__value-container {
    cursor: text;
  }
}
