@import (reference) "~Stylesheets/base";
@import "~Cobalt/typography";

.CobaltPlanCard {
  &__RecommendationReason {
    display: inline-flex;
    gap: @sp-sm;
    color: @gray600;

    &--check {
      vertical-align: text-bottom;
      color: @success900;
      height: 16px;
      width: 16px;
    }
    &--dynamic-icon {
      vertical-align: text-bottom;
      height: 18px;
      width: 18px;
    }
    &--popover {
      .BodyText {
        color: @blue25;
      }
      .CobaltPlanCard__RecommendationReason--check {
        color: @blue25;
      }
    }
  }

  &__RecommendedFor {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: @sp-lg;
    font-weight: @cobaltFontWeightLight;
  }

  &__MoreReasonsButton {
    display: inline-block;
    text-decoration: underline;
    text-decoration-thickness: 0.5px;
    // Extra class for higher specificity - required to override the default
    &.BodyText {
      color: @gray600;

      :hover {
        color: @blue600;
        text-decoration-color: @blue600;
      }
    }
  }

  &__PlanHeader {
    max-width: 150px;
  }

  &__PlanName {
    .subBody();
    .overflowEllipsis();
  }

  &__FeatureBox {
    display: inline-block;
    height: 50px;
    padding-left: @sp-lg;
    border-left: 1px solid @gray300;
    flex-shrink: 1;
    .overflowEllipsis();

    @media (min-width: @screen-sm-min) {
      padding-left: @sp-lg;
      cursor: pointer;
      min-width: 90px;
    }

    &:first-child {
      border-left: none;
      padding-left: 0;
    }

    &--label {
      .subBody();
      font-weight: @cobaltFontWeightLight;
      .overflowEllipsis();
    }

    &--value {
      margin-bottom: 0;
      .overflowEllipsis();
    }
  }

  &__MonthlyCost {
    &--caption {
      text-wrap: nowrap;
    }
  }
}
