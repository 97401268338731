@import "~Stylesheets/base";

.TieredContributionsForm {
  &__table {
    margin-left: -8px;
    min-width: 102%;
  }

  &__inputHeader {
    text-align: left;
    min-width: 34%;
    width: 34%;
  }

  &__tooltipIcon {
    color: @gray400;
  }

  &__tierInputHeader {
    text-align: left;
    min-width: 28%;
    width: 28%;
  }

  &__iconHeader {
    min-width: 4%;
    width: 4%;
  }

  &__removeIcon {
    &.Button--small {
      padding: 0px;
    }
  }

  &__buttonContainer {
    width: 98%;
  }

  &__modalLink {
    float: right;
  }
}
