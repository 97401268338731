@import "~Stylesheets/base";

.DropdownCobalt {
  margin: 0px;
  padding: 12px;
  border-radius: 2px;
  border: 0px;
  min-width: 186px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0),
    0px 1px 2px 0px rgba(0, 0, 0, 0.24), 0px -1px 1px 0px rgba(0, 0, 0, 0.04);
  &__Option {
    padding: 8px;
    cursor: pointer;
    &:hover {
      border-radius: 2px;
      background-color: @gray100;
    }
  }
  &__Toggle {
    cursor: pointer;
  }
  &__MenuToggleButton {
    padding: 0;
    border: 0;
    height: 40px;
    width: 40px;
    background-color: transparent;
    color: inherit;

    &:hover {
      background-color: @gray200;
    }
  }

  &__severity {
    &--danger {
      color: @error900;
    }
  }
}
.menu-button {
  top: 30px;
  left: 15px;
}
.menu-button-pull-right {
  top: 30px;
  right: 15px;
}
.menu-icon {
  padding: 12px;
  width: 16px;
  height: 16px;
}
