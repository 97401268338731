@import "~Stylesheets/base";
@import "./typography";

.CobaltTabs > .nav.nav-tabs {
  border-bottom: 1px solid var(--Neutral-300, #E0E0E0);
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.CobaltTabs > .nav.nav-tabs > li > a {
  border: none;
  background: inherit;
  color: var(--Neutral-700, #616161);
  padding: 0px 4px 8px 4px;
}

.CobaltTabs > .nav.nav-tabs > li.active > a {
  border-radius: 0px 0px 2px 2px;
  border-bottom: 3px solid var(--Primary-500, #3E69B3);
  color: var(--Primary-500, #3E69B3);
}
