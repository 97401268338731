@import "~Stylesheets/base";
@import "./typography";

.CobaltTable {
  width: 100%;
  max-width: 100%;

  &__Row {
    height: 40px;
    border-top: 1px solid @gray200;

    @media (max-width: @screen-xs-max) {
      > &__HeaderCell:first-child {
        width: 48%;
      }
    }
  }

  &__Cell {
    .body2();
    color: @gray700;

    &:first-child {
      font-weight: @cobaltFontWeightBold;
      color: @gray900;
    }
  }

  &__HeaderCell {
    .caption();
    font-weight: @cobaltFontWeightBold;
    text-transform: uppercase;
    background-color: @blue25;
  }

  &__HeaderCell, &__Cell, &__FooterCell {
    padding: 4px 8px 4px 8px;
    vertical-align: middle;
  }

  &--subRow {
    background-color: @gray100;
    border-radius: 4px;
    border-top: none;

    .CobaltTable__Cell:first-child {
      padding-left: 16px;
      .body2();
      color: @gray700;
      font-weight: 500;
    }

    + .CobaltTable__Row {
      border-top: none;
    }
  }

  &--headless {
    border-top: none;
  }

  &--expandedRow {
    background-color: @gray100;

    + .CobaltTable__Row {
      border-top: none;
    }
  }

  &__ExpandedSection {
    padding: 0;

    .CobaltTable__Row {
      border: none;
    }

    .CobaltTable__Cell {
      background-color: @gray100;
      .subBody();
      color: @gray700;
    }

    // give the third cell in an expanded row enough space to match the
    // cell containing the arrow icon in the main row
    .CobaltTable__Cell:nth-child(3) {
      min-width: 26px;
    }
  }

  &__FooterCell {
    .body2();
    font-weight: @cobaltFontWeightBold;
    color: @gray900;
  }
}
