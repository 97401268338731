@import "~Stylesheets/base";

.VerticalBreadcrumbs {
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 270px;
  }

  &__breadcrumb {
    display: flex;
    align-items: stretch;
    padding: 14px 0;

    &:hover {
      .VerticalBreadcrumbs__currentMarkerIcon {
        border: solid 8px @blue600;
      }
    }
  }

  &__markerwrapper {
    position: relative;
    align-items: center;
    display: flex;
    box-sizing: content-box;
    z-index: 0;

    &--last::before {
      display: none !important;
    }

    &--incomplete::before {
      background: @gray300 !important;
    }
  }

  &__markerwrapper::before {
    content: "";
    display: block;
    width: 4px;
    height: 135%;
    background: @greenA700;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }

  &__marker {
    width: 44px;
    height: 44px;
    background: @white;
    border-radius: 50%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;

    &--complete {
      color: @greenA700;
    }

    &--current {
      color: @primaryColor;
    }

    &--incomplete {
      color: @gray300;
      height: 32px;
      width: 32px;
      margin-left: 6px;
      margin-right: 6px;
    }
  }

  &__currentMarkerIcon {
    display: block;
    box-sizing: border-box;
    border-radius: 50%;
    border: solid 8px @primaryColor;
    background: @white;
    width: 32px;
    height: 32px;
  }

  &__label {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  &__title {
    font-weight: 600;
    margin-bottom: 2px;
  }

  &__description {
    color: @gray700;
  }
}
