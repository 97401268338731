// Globally available classes that are used for the styleProps utility.
@import (reference) "~bootstrap-less/bootstrap/variables";
@import (reference) "./variables";

.generateMedia(@mediaKey) {
  .spacingMixin(@propertyKey, @property) {
    .generateSizes(@sideKey, @ruleset) {
      .generate(@size, @value) {
        .@{mediaKey}@{propertyKey}@{sideKey}-@{size} {
          @ruleset();
        }
      }

      .generateIntuit(@size, @value) {
        .@{mediaKey}@{propertyKey}@{sideKey}-@{size}--intuit {
          @ruleset();
        }
      }

      .generate(0, 0);
      .generate(~"none", 0);
      .generate(1, @sp-1);
      .generate(~"xs", @sp-1);
      .generate(2, @sp-2);
      .generate(~"sm", @sp-2);
      .generate(3, @sp-3);
      .generate(~"md", @sp-3);
      .generate(4, @sp-4);
      .generate(~"lg", @sp-4);
      .generate(5, @sp-5);
      .generate(~"big", @sp-5);
      .generate(6, @sp-6);
      .generate(~"xl", @sp-6);

      .generateIntuit(0, 0);
      .generateIntuit(1, @gridSpacingIntuit);
      .generateIntuit(2, @gridSpacingIntuit * 2);
      .generateIntuit(3, @gridSpacingIntuit * 4);
      .generateIntuit(4, @gridSpacingIntuit * 5);
      .generateIntuit(5, @gridSpacingIntuit * 10);
      .generateIntuit(6, @gridSpacingIntuit * 15);
    }

    .generateSizes(~"", {
      @{property}: @value;
    });
    .generateSizes(~"x", {
      @{property}-left: @value;
      @{property}-right: @value;
    });
    .generateSizes(~"y", {
      @{property}-top: @value;
      @{property}-bottom: @value;
    });
    .generateSizes(~"t", {
      @{property}-top: @value;
    });
    .generateSizes(~"b", {
      @{property}-bottom: @value;
    });
    .generateSizes(~"l", {
      @{property}-left: @value;
    });
    .generateSizes(~"r", {
      @{property}-right: @value;
    });
  }

  .spacingMixin(m, margin);
  .@{mediaKey}mx-auto,
  .@{mediaKey}ml-auto,
  .@{mediaKey}mx-auto--intuit,
  .@{mediaKey}ml-auto--intuit {
    margin-left: auto;
  }
  .@{mediaKey}mx-auto,
  .@{mediaKey}mr-auto,
  .@{mediaKey}mx-auto--intuit,
  .@{mediaKey}mr-auto--intuit {
    margin-right: auto;
  }
  .@{mediaKey}my-auto,
  .@{mediaKey}my-auto--intuit {
    margin-top: auto;
    margin-bottom: auto;
  }
  .spacingMixin(p, padding);
}

.generateMedia(~"");
@media (min-width: @screen-sm-min) {
  .generateMedia(sm);
}
@media (min-width: @screen-md-min) {
  .generateMedia(md);
}
@media (min-width: @screen-lg-min) {
  .generateMedia(lg);
}
